<!-- ============================================================== -->
<!-- Locataire sidebar -->
<!-- ============================================================== -->
@if (isLocataire()) {
<div fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="20px" class="main-menu">
    <button [disabled]="isLoggedAs" [disableRipple]="true" mat-flat-button class="btn-display sidebar-btn-bottom"
            (click)="deconnexion()">
        <div fxLayout="row" fxLayoutAlign="space-around">
            <i-feather class="img-icon left-align m-b-5" name="{{ rsc.buttons.deconnexion.icon }}"></i-feather>
            <span class="sidebar-btn-label">{{ rsc.buttons.deconnexion.name }}</span>
        </div>
    </button>
    
    <div class="menu-user">{{ userName }}</div>
    
    <div fxLayout="row" fxLayoutAlign="space-between">
        @if (!onAucunContratPage) {
        <a tabindex="0" title="Parametres" (click)="openParameters()">
            <i-feather class="menu-icon" class="hooverBlue clickable" name="{{ rsc.buttons.parametres.icon }}"
                [ngClass]="rsc.buttons.parametres.icon">
            </i-feather>
        </a>
        }

        @if (showInstallAppButton) {
        <a tabindex="0" title="Installer l'application" fxLayout="column" fxLayoutAlign="space-between center"
            (click)="installPWA()" class="block-pwa">
            <i-feather class="menu-icon" name="{{ rsc.buttons.pwa.icon}}" [ngClass]="rsc.buttons.pwa.icon">
            </i-feather>
            <span class="sidebar-pwa-title">{{ rsc.buttons.pwa.title}}</span>
        </a>
        }
    </div>

    @if (!hasMenuItems) {
    <mat-card appearance="outlined">
        <app-spinner-and-error [isLoading]="!hasMenuItems"></app-spinner-and-error>
    </mat-card>
    }

    @if (hasContratsActifs) {
    <mat-nav-list class="menu-list" [disableRipple]="true" appAccordion>
        <!-- TODO: Angular 17 à traiter -->
        <ng-container *ngFor="let menuitem of menuItems; last as last">
            @if (menuitem.type !== 'hidden') {
            <mat-list-item class="sidebar-mat-list-item" appAccordionLink routerLinkActive="selected"
                group="{{menuitem.url}}">
                <!-- Simple menu -->
                @if (menuitem.type === 'link') {
                <a (click)="closeMenu()" appAccordionToggle [routerLink]="[baseUrl, menuitem.url]">
                    <app-icon [class]="'menu-icon'" [iconType]="menuitem.iconType" [icon]="menuitem.icon"></app-icon>
                    <span class="sidebar-simple-menu">{{ menuitem.name }}</span>
                    <span aria-hidden="true" fxFlex></span>
                    <i-feather class="sidebar-simple-menu" name="arrow-right" aria-hidden="true"></i-feather>
                </a>
                }

                <!-- Sub menu -->
                @if (menuitem.type === 'sub') {
                <mat-nav-list [disableRipple]="true" fxLayout="row" class="sub-item" routerLinkActive="selected">
                    <app-icon [class]="'menu-icon'" [iconType]="menuitem.iconType" [icon]="menuitem.icon"></app-icon>
                    <div fxLayout="column">
                        @for ( childitem of menuitem.children; track childitem) {
                        <mat-list-item class="sidebar-mat-list-item" appAccordionLink group="{{childitem.url}}">
                            <a (click)="closeMenu()" fxLayout="row" fxLayoutAlign="space-between stretch" class=""
                                appAccordionToggle [routerLink]="[baseUrl, childitem.url]">
                                <span class="sidebar-sub-menu">{{ childitem.name }}</span>
                                <span aria-hidden="true" fxFlex></span>
                                <i-feather class="menu-arrow sidebar-sub-menu" name="arrow-right"
                                    aria-hidden="true"></i-feather>
                            </a>
                        </mat-list-item>
                        }

                    </div>
                </mat-nav-list>
                }

                @if (!last) {
                <mat-divider [inset]="true"></mat-divider>
                }

            </mat-list-item>
            }

        </ng-container>
    </mat-nav-list>
    }


    <span aria-hidden="true" fxFlex></span>

    <!-- Buttons -->
    @if (hasContratsActifs) {
    <button class="sidebar-btn-contactez-nous" mat-flat-button
        [ngClass]="{'selected': router.isActive(rsc.buttons.contact.url, true)}"
        (click)="onButtonClick(rsc.buttons.contact.url)">
        <div fxLayout="row" fxLayoutAlign="space-around center">
            <span>{{ rsc.buttons.contact.name}}</span>
            <mat-icon class="sidebar-small-icon white">{{ rsc.buttons.contact.icon }}</mat-icon>
        </div>
    </button>
    }

    <div class="sidebar-block-btn" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px">
        
        <button [disableRipple]="true" mat-flat-button class="btn-display sidebar-btn-bottom"
            (click)="openAccessibility()">
            <div fxLayout="row" fxLayoutAlign="space-around center">
                <i class="img-icon left-align" [ngClass]="rsc.buttons.accessibility.icon"></i>
                <span class="sidebar-btn-label">{{ rsc.buttons.accessibility.name }}</span>
            </div>
        </button>
    </div>
</div>
}


<!-- ============================================================== -->
<!-- Admin sidebar -->
<!-- ============================================================== -->
@if (!isLocataire()) {
<div fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="20px" class="main-menu">
    <div class="menu-user">{{ userName }}</div>

    <mat-nav-list class="menu-list" [disableRipple]="true" appAccordion>
        <!-- TODO : Angular 17 à traiter -->
        <mat-list-item class="sidebar-mat-list-item" appAccordionLink *ngFor="let menuitem of menuItems; last as last"
            routerLinkActive="selected" group="{{menuitem.url}}">

            <!-- Simple menu -->
            @if (menuitem.type === 'link') {
            <a (click)="closeMenu()" appAccordionToggle [routerLink]="[baseUrl, menuitem.url]">
                <app-icon [class]="'menu-icon'" [iconType]="menuitem.iconType" [icon]="menuitem.icon"></app-icon>
                <span class="sidebar-simple-menu">{{ menuitem.name }}</span>
                <span aria-hidden="true" fxFlex></span>
                <i-feather class="sidebar-simple-menu" name="arrow-right" aria-hidden="true"></i-feather>
            </a>
            }

            <!-- Sub menu -->
            @if (menuitem.type === 'sub') {
            <a class="" appAccordionToggle href="javascript:;">
                <app-icon [class]="'menu-icon'" [iconType]="menuitem.iconType" [icon]="menuitem.icon"></app-icon>
                <span class="sidebar-simple-menu">{{ menuitem.name }}</span>
                <span fxFlex></span>
                <mat-icon class="dd-icon sidebar-simple-menu">keyboard_arrow_down</mat-icon>
            </a>
            }

            @if (menuitem.type === 'sub') {
            <mat-nav-list [disableRipple]="true" class="sub-item-admin">
                @for ( childitem of menuitem.children ; track childitem) {
                <mat-list-item class="sidebar-mat-list-item" routerLinkActive="selected">
                    @if (childitem.type === 'link') {
                    <a (click)="closeMenu()" [routerLink]="[baseUrl, menuitem.url, childitem.url ]" class="relative"
                        routerLinkActive="selected">{{
                        childitem.name}}</a>
                    }

                </mat-list-item>
                }
            </mat-nav-list>
            }

            @if (!last) {
            <mat-divider [inset]="true"></mat-divider>
            }

        </mat-list-item>
    </mat-nav-list>

    <span aria-hidden="true" fxFlex></span>

    <div mat-dialog-actions fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="5px">
        <button [disabled]="true" [disableRipple]="true" mat-flat-button class="btn-display" (click)="deconnexion()">
            <i-feather class="img-icon left-align m-b-5" name="{{ rsc.buttons.deconnexion.icon }}"></i-feather>
            <span>{{ rsc.buttons.deconnexion.name }}</span>
        </button>
    </div>
</div>
}